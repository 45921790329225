<div class="row h-100 align-items-center">
    <div class="col-xs-8 col-md-6">
        <h1><b>Phimosis Stretches</b></h1>
    
        <p class="lead">Phimosis Stretches is an app that helps you cure your Phimosis on your own using a variety of stretch exercises. The hardest part of curing phimosis on your own is performing the correct workouts, performing workouts for the correct amount of time for you and remembering to do the workouts consistently, this app solves those issues. 
          <br><br>
          By enabling you to set up customised reminders, having full control over workout settings and manging workout times for you, all you have to do is perform the stretches.
          <br><br>
          Download now, for free and start curing your phimosis.</p>
    
        <a href="https://apps.apple.com/app/phimosis-stretcher/id1513978220" target="_blank">
          <img src="../assets/app-store-button.svg" height="75px" width="150px">
        </a>
      </div>
      <div class="col-md-1"></div>
      <div class="col-xs-4 col-md-5">
        <img class="img-fluid float-right" src="assets/app-image.png">
    </div>
</div>
